<template>
  <div>
    <b-card no-body style="min-height: 750px;" bg-variant="dark">
      <!-- <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
        <img src="@/icon/665778.png" class="img-rounded img-responsive" style="width: 2em;" /> Yapay Zeka Modelleri Arama Ekranı
      </b-card-header> -->
      <b-row style="margin-top: 30px;">
        <b-col sm="12" md="1">
        </b-col>
        <b-col sm="12" md="8">
          <b-row :style="'height:' + (d_drawSettings.canvas.height + 50) + 'px; margin: 0px;'" class="animated fadeIn">
            <b-col sm="12">
              <template v-if="d_algorithmData.show">
                <draw-algorithm :p_algorithmData="d_algorithmData" :p_drawSettings="d_drawSettings"></draw-algorithm>
              </template>
            </b-col>
          </b-row>
          <b-row style="margin-bottom: 30px;">
            <b-col sm="12" style="text-align: center;">
              <strong>WAI</strong>
            </b-col>
          </b-row>
          <b-row style="margin-bottom: 30px;">
            <b-col sm="12">
              <b-form-group label="" label-for="search_patient">
                <b-input-group>
                  <b-form-input style="height: 40px; border-radius: 10px;" id="wisdomera_ai" placeholder="Konuşmak istediğiniz karar destek algoritmaları, yapay zeka modelleri hakkında arama yapabilirsiniz" type="text" v-model="d_searchWisdomEraAiRegion" @keydown.enter.native="ClickEnter"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="white" @click="f_searchWaim()" style="margin-right: 10px;"><i class="fa fa-search"></i> </b-button>
                    <b-button variant="white" @click="d_searchWisdomEraAiRegion = ''" style="margin-right: 10px;"><i class="fa fa-trash"></i> </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <wai-list :p_waiList="d_waimList"></wai-list>
        </b-col>
        <b-col sm="12" md="3">
          <wai-list :p_waiList="d_waiData.wai"></wai-list>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_fastAnalyzeModeShowModal" @close="d_fastAnalyzeModeShowModal = false" :width="'1000'">
      <h3 slot="header">Hızlı Analiz Modu</h3>
      <div slot="body">
        <polestar-fast-analyze :column_list="column_list" :option_data="option_data" :p_selectedProjectData="d_selectedProjectData" :mother_children="mother_children"></polestar-fast-analyze>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_fastAnalyzeModeShowModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as WaiList
} from '@/components/widgets/WaiList';
import {
  default as DrawAlgorithm
} from '@/components/widgets/DrawAlgorithm';
import WaiService from '@/services/wai';
import OptionService from '@/services/option';
import GlobalService from '@/services/global';
import {
  default as PolestarFastAnalyze
} from "@/components/widgets/PolestarFastAnalyze";
import vSelect from 'vue-select';
import PoleStarService from '@/services/polestar';
import { ClsRule } from '@/services/public/rule';
import { ClsPolestar } from "@/services/public/polestar";
import { mapGetters } from 'vuex';
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';
import {
  default as PolestarResult
} from "@/components/widgets/PolestarResult";
import {
  default as Modal
} from '@/components/widgets/Modal';
import moment from 'moment';

export default {
  name: 'PolestarWaimSearch',
  components: {
    Modal,
    WdmRuleA,
    PolestarResult,
    vSelect,
    PolestarFastAnalyze,
    DrawAlgorithm,
    WaiList
  },
  props: {
    p_waimList: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  created: function () {
    if (this.p_waimList && this.p_waimList.length > 0) {
      this.d_waimList = JSON.parse(JSON.stringify(this.p_waimList));
    }
    if (this.$route.query.waim_text) {
      this.d_searchWisdomEraAiRegion = this.$route.query.waim_text;
      this.f_searchWaim();
      // this.$route.query = {};
      // this.$router.push({ 'path': '/whsmodules/polestar/waim', 'query': {} });
    }
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    this.f_getNextWai();
    this.f_getNextWaiStartInterval();
  },
  destroyed () {
    clearInterval(this.d_nextWaiInterval);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  data () {
    return {
      user: {},
      mother_children: { 'forceUpdate': 0, 'send_table_to_article': 0, 'article_naming': 0 },
      d_fastAnalyzeModeShowModal: false,
      d_searchWisdomEraAiRegion: '',
      d_waimList: [],
      d_selectedProjectData: {},
      column_list: [],
      option_data: {},
      d_algorithmData: {
        'show': true,
        'data': [],
        'dmp_rule_slider': 'all_dmp',
        'stop': 0,
        'next': 0,
        'update_canvas': 0,
        'name': ''
      },
      d_drawSettings: {
        'canvas': {
          'height': 250,
          'width': window.innerWidth
        },
        'header_variant': 'dark',
        'biggest_circle_radius': 30,
        'show_text': 1,
        'size': 200,
        'animation': 0,
        'lineWidth': 3,
        'colorAnimation': 1,
        'colorSpeed': 500,
        'rotateAnimation': 1,
        'shift': 1,
        'mainColor': '',
        'speed': 100,
        'deep_animation_speed': 500,
        'max_deep': 0,
        'deep': 0,
        'deep_direction': 'to_children',
        'deep_animation': 0,
        'show_buttons': 0
      },
      d_waiData: {
        'last_wai': '0',
        'wai': [],
        'count': 5,
        'published_dmp_count': 0
      },
      d_nextWaiInterval: 0
    }
  },
  methods: {
    f_getNextWai: function () {
      let query = 'last_wai=' + this.d_waiData.last_wai.toString() + '&count=' + this.d_waiData.count.toString();
      WaiService.get_next_wai(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_waiData.last_wai = resp.data.result.last_wai;
            this.d_waiData.wai = resp.data.result.wai;
          } else {
            console.log('Error :' + resp.data.message);
          }
        });
    },
    f_getNextWaiStartInterval: function () {
      this.d_nextWaiInterval = setInterval(function () {
        this.f_getNextWai();
      }.bind(this), 10000);
    },
    ClickEnter: function () {
      if (event.which === 13) {
        this.f_searchWaim();
      }
    },
    f_searchWaim: function () {
      if (this.d_searchWisdomEraAiRegion) {
        this.d_waimList = [];
        let data = { 'search': this.d_searchWisdomEraAiRegion };
        if (!this.$route.query.waim_text || (this.$route.query.waim_text && this.$route.query.waim_text !== this.d_searchWisdomEraAiRegion)) {
          this.$router.push({ 'path': '/wai/search', 'query': { 'waim_text': this.d_searchWisdomEraAiRegion } });
        }
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Yapay zeka modellerinde arama işlemi başlatıldı. Lütfen bekleyiniz.' } });
        WaiService.search_wai(data)
          .then(resp => {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.d_waimList = resp.data.result.list;
              this.$forceUpdate();
            } else {
              alert('Error :' + resp.data.message)
            }
          });
      } else {
        alert('Lütfen bir şeyler yazın');
      }
    },
    DateFormatWithHourMin: function (date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    f_goToWaimWdm3Project: function (wdm3_id) {
      let route_data = {
        'name': 'polestarwaimtalk',
        'path': '/whsmodules/polestar/waim/talk',
        'query': { 'waim_id': wdm3_id }
      };
      this.$router.push(route_data);
    },
    f_goToWaimWdm24: function (wdm24_id) {
      let route_data = {
        'name': 'polestarwaimtalkalgorithm',
        'path': '/whsmodules/polestar/waim/talk-algorithm',
        'query': { 'waim_id': wdm24_id }
      };
      this.$router.push(route_data);
    }
  },
  watch: {}
};

</script>

<style type="text/css">


</style>

